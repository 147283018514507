import {IBKRSide} from "../dict/auto-trade/ibkr-side.enum";
import {IBKROrderType} from "../dict/auto-trade/ibkr-order-type.enum";
import {OptionType} from "../dict/option-type.enum";
import {AutoTradeSecurityTypeEnum, AutoTradeSetupStatusEnum} from "../dict";
import {Analysis, IAutoTrade, IAutoTradeSetup, ISignalAlert, ITradeTemplate, StockDirectionType} from "../interface";
import {AnalysisHelper} from "../analysis";

export class AutoTradeHelper {

    public static optionsInitialValues:ITradeTemplate = {
        // contracts: null,
        strikePriceOffset: 0,
        minExpirationDays: 2,
        maxExpirationDays: 6,
        // tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
        ibkr: {
            side: IBKRSide.BUY,
            orderType: IBKROrderType.LMT_PB,
        },
        optionType: OptionType.CALL,
    };

    static getDefaultOptionType (setupOptions: Analysis.InputParams) : OptionType {
        const dir = AnalysisHelper.direction(setupOptions);
        return  dir === StockDirectionType.DOWN ? OptionType.PUT : OptionType.CALL;
    }

    static createTemplates(signalAlert:ISignalAlert, statusId: AutoTradeSetupStatusEnum) {
        const defaultValues = AutoTradeHelper.optionsInitialValues;
        // const analysisSetup: Partial<TradeSetupFilterOptions> = signalAlert.data;
        const optionType = this.getDefaultOptionType(signalAlert.data);
        const defOrderType = defaultValues.ibkr?.orderType
        const optionTemplate: ITradeTemplate = {
            maxExpirationDays: defaultValues.maxExpirationDays as number,
            minExpirationDays: defaultValues.minExpirationDays as number,
            strikePriceOffset: defaultValues.strikePriceOffset as number,
            optionType: optionType,
            ibkr: {
                side: IBKRSide.BUY, // BUY for both CALL and PUT
                orderType: defOrderType,
            }
        };
        const equityTemplate = {
            ibkr: {
                side: optionType===OptionType.CALL?IBKRSide.BUY:IBKRSide.SELL,
                orderType: defOrderType,
            }
        } as ITradeTemplate;
        const prepareSetup = (securityType:AutoTradeSecurityTypeEnum, template:ITradeTemplate)=>{
            const newTradeSetup: IAutoTradeSetup = {
                statusId: statusId,
                tradeSecurityTypeId: securityType,
                name: signalAlert.name,
                signalAlertId: signalAlert.id,
                maxActiveTrades: 2,
                quantityPerTrade: 1,
                maxTradesPerDay: 10,
                tradeTemplate: template,
                // analysisSetup: analysisSetup
            } as IAutoTradeSetup;
            return newTradeSetup
        }
        return [
            prepareSetup(AutoTradeSecurityTypeEnum.OPTION, optionTemplate),
            prepareSetup(AutoTradeSecurityTypeEnum.EQUITY, equityTemplate),
        ] as IAutoTradeSetup[];
    }

    static calcRealizedProfitLossTradeIbkr(trade: IAutoTrade) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:trade.ibkr.closePriceAvg,
            quantity:trade.ibkr.closedQuantity,
            multiplier:this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    static calcUnrealizedProfitLossIbkr (trade: IAutoTrade, currentPrice: number) {
        return this.calcProfitLoss({
            open:trade.ibkr.openPriceAvg,
            close:currentPrice,
            quantity: trade.ibkr.openedQuantity-trade.ibkr.closedQuantity,
            multiplier: this.multiplier(trade),
            isLong: trade.ibkr.openOrders?.filter(v=>v.orderSide===IBKRSide.BUY).length>0,
        });
    }

    public static calcProfitLoss(p:{open: number, close: number, quantity: number, multiplier:number, isLong:boolean}) {
        const amount = (p.close - p.open) * p.quantity * p.multiplier;
        return p.isLong ? amount : -amount;
    }

    static multiplier(trade:IAutoTrade) {
        return trade.securityTypeId===AutoTradeSecurityTypeEnum.OPTION?100:1;
    }
}
