import {
  AutoTradeHelper,
  IBKROrderType,
  IBKROrderTypeHelper,
  IBKRSide,
  IBKRSideHelper,
  OptionType
} from "predictagram-lib";

export interface ITradingFormParameters {
  ibkr: {
    side: IBKRSide
    orderType: IBKROrderType,
  },
  // ibkrOrderType: IBKROrderType
}

export interface IEquityTradingFormParameters extends ITradingFormParameters{
  shares?: number, // it's not requires for submit
  // strikePriceOffset: number,
  // tradeSecurityTypeId: AutoTradeSecurityTypeEnum,

}


export interface IOptionTradingFormParameters extends ITradingFormParameters{
  contracts: number,
  minExpirationDays: number,
  maxExpirationDays: number,
  strikePriceOffset: number,
  // tradeSecurityTypeId: AutoTradeSecurityTypeEnum,
  optionType: OptionType,
  // ibkrSide: IBKRSide,
  // ibkrOrderType: IBKROrderType
}
export class TradeSetupModel {
  public static optionType = new Map([
    [OptionType.CALL, 'Call'],
    [OptionType.PUT, 'Put'],
  ]);

  public static orderType = IBKROrderTypeHelper.names;

  public static ibkrSide = IBKRSideHelper.names;

  public static daysAway = new Map(
    Array(31).fill(null).map((_, i)=>[i, i.toString()])
  )

  public static optionsInitialValues = AutoTradeHelper.optionsInitialValues;
  // public static optionsInitialValues: Partial<IOptionTradingFormParameters> = {
  //     // contracts: null,
  //     strikePriceOffset: 0,
  //     minExpirationDays: 2,
  //     maxExpirationDays: 6,
  //     // tradeSecurityTypeId: AutoTradeSecurityTypeEnum.OPTION,
  //     ibkr: {
  //       side: IBKRSide.BUY,
  //       orderType: IBKROrderType.LMT_PB,
  //     },
  //     optionType: OptionType.CALL,
  // }


  public static equityInitialValues: IEquityTradingFormParameters = {
    // shares: 1,
    // contracts: null,
    // strikePriceOffset: null,
    // tradeSecurityTypeId: AutoTradeSecurityTypeEnum.EQUITY,
    ibkr: {
      side: IBKRSide.BUY,
      orderType: IBKROrderType.MKT,
    }
  }


}
