import React, {useState} from "react";
import {StockSignalsEnum, StockSignalsHelper} from "predictagram-lib";

export const SignalDescription: React.FunctionComponent = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <button onClick={()=>setIsExpanded((isExpanded) => !isExpanded)}>Signals Info Show/Hide</button>
      { isExpanded && <div>
        {Array.from(StockSignalsHelper.signalsMap().entries()).map(([sg,name])=>{
          const info = StockSignalsHelper.info(sg);
          if (!info.description) {
            return ''
          }
          return <p key={sg} id={sg}>
            <b>{name}:</b> {info.description}
          </p>
        })}
      </div>}
    </>
  )
}
