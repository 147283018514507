import {
    CumulativeChartLines,
    PredictionTypeEnum,
    SignalAlertPerfTypeEnum,
    SignalAlertStatusEnum,
    StockSignalsEnum,
    SignalAlertCategoryEnum
} from "../../dict";
import {FilterUserAverageScoreRange} from "../api";
import {CumulativeStatsResult, CumulativeStatsResultDay, MinMaxStatsAtr, SupResLine} from "../../analysis";
import {IQuote, IQuoteFull} from "./quote";
import {StockCalculatedLines} from "./chart";
import {ActivationAlgo} from "./activation-algo";

// tslint:disable-next-line:no-namespace
export namespace Analysis {

    export enum StockSource {
        TIINGO      = 'tiingo',
        IBKR        = 'ibkr',
    }
    // tslint:disable-next-line:no-empty-interface
    export interface InputParams extends AnalysisInputParams {

    }

    export interface InputParamsEx extends InputParams {
        inputOptions?: {
            stockSource?: StockSource,
        }
    }

    export interface CumulativeChartDataDay {
        cumulativeData: CumulativeStatsResultDay,
        stats: {
            stock: IQuoteFull[],
        },

    }
    // tslint:disable-next-line:no-empty-interface
    export interface Quote extends IQuote {

    }

    // tslint:disable-next-line:no-empty-interface
    export interface QuoteFull extends IQuoteFull {

    }

    export interface Prediction {
        id: number;
        value: number;
        valueTime: number;
        valueTimeHourEst?: number;
        valueDeviation: number;
        valueStock: number;
        scoreUserLast30: number;
        stockSymbolId: number;
        typeId: PredictionTypeEnum,
        fromStockSymbolId?: number;
    }

    // tslint:disable-next-line:no-empty-interface
    export interface InputParams extends AnalysisInputParams {

    }

    export interface CumulativeInputParams {
        analysis: InputParams,
        // also return lines
        options?: {
            lines?: CumulativeChartLines[]
        }
    }
}

export enum StockTriggerType {
    PREDICTION      = 1,
    SIGNAL          = 2,
}

export enum StockDirectionType {
    UP  = 1,
    DOWN = -1,
}

export enum SignalRepeatType {
    PRIMARY     = 1,
    REPEAT      = 2,
}

export class StockTypesHelper {
    protected static dirTypes = new Map([
        [StockDirectionType.UP, 'UP'],
        [StockDirectionType.DOWN, 'DOWN'],
    ]);

    public static directionTypes() {
        return StockTypesHelper.dirTypes;
    }

    public static directionType(t: StockDirectionType): string {
        return StockTypesHelper.directionTypes().get(t) as string;
    }
}

export interface SignalOptions {
    candleSizePriceRate?: number;
    candleSizeRate?: number;
    candlesCount?: number;
    stockGapRate?: number;
    stockGapIntervalSecs?: number;
    rangeCount?: number;
    durationSecs?: number;
    minCount?: number;
    priceRate?: number;
    distanceRate?: number;
    repeatedSignal?: boolean;

    volumeRate?: number;
    volumeSmaCount?: number; // prev points to use for SMA count
}

// Part of options should be provided from UI, can be added later by interal logic to signals
export interface SignalOptionsFull extends SignalOptions {
    prevDayAverage?: {high:number,low:number},
    openRangeAverage?: {high:number,low:number},
    openPrice?: number;
    prevDayClosePrice?: number;
    lines?: {
        // vwap?: LinePoints,
        // sma120?: LinePoints,
        supRes1hStock?: SupResLine[],
        supRes3dStock?: SupResLine[],
    } & StockCalculatedLines,
    minMax2hVd30m?: MinMaxStatsAtr,
    prevDaysPrices?: IQuoteFull[]; // prices for previous days, for emaNday signals
    prevDayStats?:IQuoteFull[]; // for sup/res daily
}

export interface SignalPatternInput {
    currPos: number,
    stock: Readonly<IQuoteFull[]>,
    options: SignalOptionsFull,
}

// @TODO: maybe merge to common pattern
export interface SignalPatternInputPred {
    currPos: number,
    predictions: Readonly<Analysis.Prediction[]>,
    options: SignalOptionsFull,
}

export interface SignalPrice {
    price: number, // price for analysis calculation
    y?: number,
    dir: StockDirectionType,
}
export enum SignalCondition {
    AND         = 'and',
    OR          = 'or',
}

export interface SignalItem {
    type: StockSignalsEnum;
    condition?: SignalCondition, // default "AND", it has only simple OR and AND for now, temporary simple impelementation
    exclude?: boolean, // default false
    repeatTypes: SignalRepeatType[];
    directionTypes: StockDirectionType[];
    repeatSecs: number;
    oppositeDirection: boolean;
    cancellationSecs: number;
    // custom options per signal
    options?:  SignalOptions|SignalOptionsFull,
}

export interface SignalSetup {
    signals: SignalItem[];
    intersectionSecs?: number; // required when 2 or more signals
    strictSequence?: boolean; // default is FALSE, only for "AND" cond
    // condition: 'or'|'and';
}

export enum GroupDirectionType {
    DIR_SUM = 1,
    PERCENTAGE = 2,
}

export interface AnalysisCumulativeChartData {
    stats: {
        pred: Readonly<Analysis.Prediction>[],
        stock: Readonly<IQuoteFull>[],
        prevDayClose: null|IQuoteFull,
    },
    cumulativeData: CumulativeStatsResult,
}

// tslint:disable-next-line:max-classes-per-file
export class AnalysisDirGroupEntity {
    profitCount: number = 0;
    profitRate: number = 0;
    profitRateSum: number = 0;
    profitAmountSum: number = 0;
    profitAmountAvg: number = 0;

    lossCount: number = 0;
    lossRate: number = 0;
    lossRateSum: number = 0;
    lossAmountSum: number = 0;
    lossAmountAvg: number = 0;

    atCloseCount: number = 0;
    atCloseProfitRateSum: number = 0;
    atCloseRate: number = 0;
    atCloseAmountSum: number = 0;
    atCloseAmountAvg: number = 0;

    atExitSignalCount: number = 0;
    atExitSignalRateSum: number = 0;
    atExitSignalRate: number = 0;
    atExitSignalAmountSum: number = 0;
    atExitSignalAmountAvg: number = 0;

    atExitStepSizeCount: number = 0;
    atExitStepSizeRateSum: number = 0;
    atExitStepSizeAmountSum: number = 0;
    atExitStepSizeRate: number = 0;
    atExitStepSizeAmountAvg: number = 0;

    totalCount: number = 0; // number of items to get "sumDirection"
    profitableTrades: number = 0; // positive profit
    groupDirection: number = null as any;
    groupDirectionType: GroupDirectionType = null as any;

    overallProfitRate: number = 0;
    overallProfitAmount: number = 0;
}
export interface AnalysisScoreCounter {
    // are necessary to calc top 3 final scores
    netDirEvPct: number;// 0.353291
    netDirSum: number;// 58
    netDirEvPctPos: number,// 0.353291
    netDirSumPos: number, // 58
    netDirEvPctNeg: number, // 0
    netDirSumNeg: number, // 0
    netDirEvPctZero: number, // 0
    netDirSumZero: number, // 0

    profitCount: number, // profit exit count
    lossCount: number, // loss exit count
    atCloseCount: number, // ahead close count
    atExitSignalCount: number, // signal exit count
    atExitStepSizeCount: number, // step size exit count
    profitAmountSum: number,
    lossAmountSum: number,
    atCloseAmountSum: number,
    atExitSignalAmountSum: number,
    atExitStepSizeAmountSum: number,
    // totalCount: 58,
    // profitableTrades: 46,
    [key:string]: number
}
export interface AnalysisScore {
    score: AnalysisScoreCounter & {
        overall: number;
        positiveDirection: number;
        negativeDirection: number;
    },
    trades: {
        total: number;
        positiveDirection: number;
        negativeDirection: number;
        zeroDirection: number;
        profitable: number;
    }
}

export interface AnalysisQuerySignalsOutputPerf {
    calcCumulDataMsec: number;
    calcCumulDataItems: number;
    cumulPredDbStatsMsec: number;
    cumulPredDbStatsItems: number;

    // cumulStatsItems: number,
    // cumulStatsMsec: number,
    stockStatsItems: number,
    stockStatsMsec: number,
}

export interface AnalysisOutputParams {
    stats: AnalysisScore;
    perfStats: {
        totalMsec: number,
        openByPredCount: number,
        openByPredMsec: number,

        openBySignal: AnalysisQuerySignalsOutputPerf,

        closeBySignal: AnalysisQuerySignalsOutputPerf,

        aggregateWindowGroupsMsec: number,
        calcWindowDataMsec: number,
        openTriggersQueryStockMsec: number,

        [key:string]: any,

    },
    result: AnalysisDirGroupEntity[],
    trades?: AnalysisTrade[], // return per input request
}

export interface AnalysisTrade {
    directionSum: number,
    profit: { // calculated using open-close prices
        amount: number,
        rate: number,
    },
    open:  {
        time: number,
        // price: number,
    }
    close: {
        time: number,
        // price: number,
    }
}

export interface PrevCurDayPriceGap {
    direction: StockDirectionType, // gap up or down
    diffMinRate: number, // gap size in pct/100

}

export interface AnalysisInputParams {

    groupDirectionType: GroupDirectionType;

    openTriggerType:  StockTriggerType;
    openTriggerDirectionTypes?: StockDirectionType[];

    openSignalSetup?: SignalSetup;
    // openSignalType: StockSignalsEnum;
    // openSignalRepeatTypes: SignalRepeatType[];
    // openSignalRepeatSecs: number;
    // openSignalOppositeDirection: boolean;

    closeSignalSetup?: SignalSetup;
    // closeSignalType: StockSignalsEnum;
    // closeSignalRepeatTypes: SignalRepeatType[];
    // closeSignalRepeatSecs: number;
    // closeSignalOppositeDirection: boolean;

    closeStepSizeRate: number|null;

    prevCurDayPriceGap?: PrevCurDayPriceGap,

    startTime: number;
    endTime: number;

    stockSymbolId: number,

    // use extra symbol prediction's data for analysis
    // extraSymbolNames?: [],
    extraSymbolIds?: number[],

    predictionTypes: PredictionTypeEnum[], // list of types to analyze
    predictionIds: number[]|undefined;
    predictionUserIds: number[]|undefined;
    predictionFirstMinStepSizeMoveMax?: number, // step size move threshold for first min (pred made + 1m)

    // for signals and predictions
    userAverageScore?: FilterUserAverageScoreRange;

    // use preds within windows up to current pred made time
    windowTimeSecs: number, // Period of time to consider the predictions, to use for net sum of the predictions directions in the window period.

    windowMinElements: number, // Minimum number of predictions involved in the window period.
    windowMaxElements: number, // Maximum number of predictions involved in the window period.

    aheadTimeSecs: number, // Period of time ahead of prediction time to consider in, use stock stats for comparison in next N minutes

    startHourEst: number, // Remove triggers before this hour for the analysis (not for the cumulative computations).
    endHourEst: number, // Remove triggers after this hour for the analysis (not for the cumulative computations).

    // fixInvalidScores: number,
    // useExitSignal: number,

    takeProfitRate: number,
    // take_profit = st.slider("Take Profit", 0.05, 0.5, 0.15, step=0.01)

    stopLossRate: number; // default 0.15% - negative
    // stop_loss = st.slider("Stop Loss", -0.5, -0.05, -0.15, step=0.01)

    // exitSignalType: StockSignalsEnum|null;
    // signalRepeatIntervalSecs: number;

    outputOptions?: {
        returnTrades: boolean,
    }
}



export interface ISignalAlertScore {
    overall: number,
    lossCount: number,
    netDirSum: number | null,
    totalCount: number | null,
    netDirEvPct: number | null,
    profitCount: number,
    atCloseCount: number,
    netDirSumNeg: number | null,
    netDirSumPos: number | null,
    lossAmountSum: number,
    netDirEvPctNeg: number | null,
    netDirEvPctPos: number | null,
    profitAmountSum: number,
    atCloseAmountSum: number,
    profitableTrades: number | null,
    atExitSignalCount: number,
    negativeDirection: number,
    positiveDirection: number,
    atExitStepSizeCount: number,
    atExitSignalAmountSum: number,
    atExitStepSizeAmountSum: number
}

export interface ISignalAlertTrade {
    total: number,
    profitable: number,
    negativeDirection: number,
    positiveDirection: number
}

export interface ISignalAlertDetails {
    score: ISignalAlertScore,
    trades: ISignalAlertTrade
}

export interface ISignalAlertPerfScore {
    typeId: SignalAlertPerfTypeEnum;
    signalAlertId: number;
    activationId: number|null;
    score: number|null;
    activationName: string|null;
    createdAt: number,
}
export interface ISignalAlertCache {
    last7: ISignalAlertDetails,
    last14: ISignalAlertDetails,
    last30: ISignalAlertDetails,
    last90: ISignalAlertDetails,
    last150: ISignalAlertDetails
}
export interface ISignalAlert {
    id: number,
    parentId: number|null,
    name: string,
    statusId: SignalAlertStatusEnum,
    emails: string[],
    notes: string,
    todayScore?: ISignalAlertPerfScore[],
    stockSymbolId: number;
    categories: SignalAlertCategoryEnum[]|null,
    createdAt: number; // dynamic field
    cache: ISignalAlertCache;
    cacheAfterCreated: ISignalAlertCache;
    data: AnalysisInputParams & {
        // alertName: string,
        // alertEmails: string[],
        // alertSendCloseSignals: boolean,
        // last30dScore: number
      },
    options: {
        sendCloseSignals: boolean
    }
}

export interface ISignalAlertActivation {
    id: number;
    algoTypeId: SignalAlertPerfTypeEnum,
    statusId: ActivationAlgo.StatusEnum,
    name: string,
    notes: string,
    data: ActivationAlgo.ICombo,
    createdAt: number;
    performance?:Partial<ISignalAlertActivationDayPerf>[]; // desc order
}

export interface ISignalAlertActivationDayPerf {
    id: number,
    activationId: number,
    algoTypeId: SignalAlertPerfTypeEnum,
    activationName: string,
    alertId: number|null,
    alertName: string|null,
    date: string,
    score: number,
    trades: number,
    createdAt: number,
}
