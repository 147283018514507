export enum AdminReportType {
  SIGNAL_ALERT_COMBOS                           = 1,
  SIGNAL_ALERT_COMBOS_QUALIFIED_ALERTS          = 2,
  SIGNAL_ALERT_INDICATOR_DETAILS                = 3,
  SIGNAL_ALERT_REPEAT_WINNER_GAP                = 4,
}

export class AdminReportTypeHelper {
    protected static names = new Map<AdminReportType, string>([
        [AdminReportType.SIGNAL_ALERT_COMBOS, 'Signal Alert Combos'],
        [AdminReportType.SIGNAL_ALERT_COMBOS_QUALIFIED_ALERTS, 'Signal Alert Combo\'s Qualified Alerts'],
        [AdminReportType.SIGNAL_ALERT_INDICATOR_DETAILS, 'Signal Alert Indicator Details'],
        [AdminReportType.SIGNAL_ALERT_INDICATOR_DETAILS, 'Signal Alert Repeat Winner Gap'],
    ]);

    public static getNames() {
        return this.names;
    }
}
