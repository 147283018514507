import {IQuote, IQuoteFull} from "../interface";
import {MathEx} from "../utils";

interface IVolumeRow {
    volume: number;
    low: number;
    high: number;
    mid: number;
}

// interface IValueArea {
//     high: number;
//     VAH: number;
//     POC: number;
//     EQ: number;
//     VAL: number;
//     low: number;
// }

interface INakedPointOfControl {
    resistance?: number;
    support?: number;
}

export class VolumeProfileData {

    public constructor(
            /**
             * Number of recent points to calculate volume profile
             * @protected
             */
            protected interval=120,

            /**
             * Total number of volume profile rows on histogram
             * @protected
             */
            protected valueAreaRowSize: number = 20,
            protected valueAreaVolume: number = 0.68
        ) {
    }

    /**
     * Calculates the total volume, highest price, and lowest price from an array of candlesticks.
     *
     * @param quotes An array of candlesticks to analyse.
     * @returns An object containing the total volume (V_TOTAL), highest price, and lowest price of the given
     *         candlesticks.
     */
    protected sumVolumes(currPos:number, quotes: readonly IQuoteFull[]) {
        let volumeTotal: number = 0;
        let highest: number = 0;
        let lowest: number = Infinity;

        this.loopQuotes(currPos, quotes, (item)=>{
            const volume: number = item.v;
            const high: number = item.h;
            const low: number = item.l;
            volumeTotal += volume;

            if (high > highest) highest = high;
            if (low < lowest) lowest = low;
        });

        return { volumeTotal: Math.round(volumeTotal), high: highest, low: lowest };
    }

    /**
     * Generates a histogram for value area calculation and identifies the Point of Control (POC) and its corresponding
     * row.
     *
     * @param quotes An array of candlesticks to analyse.
     * @param highest The highest price in the range of candlesticks.
     * @param lowest The lowest price in the range of candlesticks.
     * @param nDecimals The number of decimal places to consider in calculations.
     * @param valueAreaRowSize Total number of volume profile rows
     * @returns An object containing the histogram, the price of the Point of Control (POC), and the row of the POC.
     */
    protected buildHistogram(currPos: number, quotes: readonly IQuoteFull[], highest: number, lowest: number, nDecimals: number, valueAreaRowSize: number) {
        let row = 0;
        const range: number = highest - lowest;
        // amount step size for each volume profile histogram line
        const stepSize: number = MathEx.round(range / valueAreaRowSize, nDecimals);
        const histogram: IVolumeRow[] = [];
        const result = {
            histogram, POC: null as (number|null), POC_ROW:null as (number|null),
        };
        // if (range <= 0) return { histogram: null, POC: null, POC_ROW: null };
        if (range<=0) {
            // data might be corruped from tiingo
            // throw new Error(`invalid input? currPos:${currPos} quotesLen:${quotes.length}`);
            return result;
        }

        // let POC_ROW: number = 0;
        // let POC: number = 0;
        let highestVolumeRow: number = 0;

        // prepare volume profile histogram lines, total number of lines is valueAreaRowSize
        while (histogram.length < valueAreaRowSize) {
            const low = Math.max(lowest, MathEx.round(lowest + stepSize * row, nDecimals));
            const high = Math.min(highest, MathEx.round(lowest + stepSize * row + stepSize, nDecimals));
            const mid = MathEx.round((low + high) / 2, nDecimals);

            histogram.push({
                volume: 0,
                low,
                mid,
                high
            } as IVolumeRow);

            row++;
        }

        // fill histogram using quotes, calc POC on fly
        this.loopQuotes(currPos, quotes, (item)=>{
            const volume: number = item.v;
            const open: number = item.o;
            const high: number = item.h;
            const low: number = item.l;
            const close: number = item.c;
            const typicalPrice: number = MathEx.round((open + high + low + close) / 4, nDecimals);
            const ROW: number = stepSize === 0 ? 0 : Math.min(valueAreaRowSize - 1, Math.floor((typicalPrice - lowest) / stepSize));

            histogram[ROW].volume += volume;

            if (histogram[ROW].volume > highestVolumeRow) {
                highestVolumeRow = histogram[ROW].volume;
                result.POC = histogram[ROW].mid;
                result.POC_ROW = ROW;
            }
        });

        return result;
        // return { histogram, POC, POC_ROW };
    }

    /**
     * Calculates the Value Area High (VAH) and Value Area Low (VAL) based on the histogram and total volume.
     *
     * @param POC_ROW The row number of the Point of Control in the histogram.
     * @param histogram An array of volume rows representing the histogram.
     * @param V_TOTAL The total volume of the candlesticks.
     * @returns An object containing the Value Area High (VAH) and Value Area Low (VAL).
     */
    protected findValueAreas(POC_ROW: number, histogram: IVolumeRow[], V_TOTAL: number, valueAreaVolume: number) {
        const VALUE_AREA_VOLUME = valueAreaVolume * V_TOTAL;

        let currentVolume = histogram[POC_ROW].volume;
        let lowerIndex = POC_ROW;
        let upperIndex = POC_ROW;

        while (currentVolume < VALUE_AREA_VOLUME) {
            const lowerVolume = lowerIndex > 0 ? histogram[lowerIndex - 1].volume : 0;
            const upperVolume = upperIndex < histogram.length - 1 ? histogram[upperIndex + 1].volume : 0;

            if (lowerVolume === 0 && lowerIndex > 0) {
                // If lower volume is 0, skip to the next lower row
                lowerIndex--;
            } else if (upperVolume === 0 && upperIndex < histogram.length - 1) {
                // If upper volume is 0, skip to the next upper row
                upperIndex++;
            } else if (lowerVolume > upperVolume && lowerIndex > 0) {
                // Expand to the side with higher volume
                lowerIndex--;
                currentVolume += histogram[lowerIndex].volume;
            } else if (upperIndex < histogram.length - 1) {
                upperIndex++;
                currentVolume += histogram[upperIndex].volume;
            } else {
                break; // Stop if no more rows to expand
            }
        }

        // Determine VAL and VAH
        const VAL = histogram[lowerIndex].low;
        const VAH = histogram[upperIndex].high;

        return { VAL, VAH };
    }

    /**
     * Calculate the Value Area for given candles.
     *
     * @param quotes Candle Array.
     * @property {number} valueAreaRowSize - The number of Value Area rows.
     * @property {number} valueAreaVolume - The Value Area percentage.
     * @returns Value Area Object.
     */
    calculate(currPos: number, quotes: readonly IQuoteFull[]) {
        // We need to start at the start of the (day / week / month), in order to filter all the quotes for the VA calculations for that period
        // current day vs previous day, current week vs previous week, current month vs previous month
        const { volumeTotal, high, low } = this.sumVolumes(currPos, quotes);
        const maxDecimals = Math.max(this.countDecimals(high), this.countDecimals(low));
        const EQ = MathEx.round(low + (high - low) / 2, maxDecimals);

        const { histogram, POC, POC_ROW } = this.buildHistogram(
                currPos,
                quotes,
                high,
                low,
                maxDecimals,
                this.valueAreaRowSize
        );
        const result = {
            VAH: null as (number|null),
            VAL:null as (number|null),
            POC:POC,
            EQ:EQ,
            low:low,
            high:high,
        }
        if (null!==POC_ROW) {
            const {VAH, VAL} = this.findValueAreas(POC_ROW, histogram, volumeTotal, this.valueAreaVolume);
            result.VAH = VAH;
            result.VAL = VAL;
        }
        return result;
    }

    protected countDecimals(value: number): number {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    }

    protected loopQuotes(currPos:number, quotes:readonly IQuoteFull[], apply:(q:IQuoteFull)=>any) {
        for (let i=currPos;i>=0&&i>=currPos-this.interval-1;i--) {
            apply(quotes[i]);
        }
    }
}
