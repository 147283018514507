import React, {useRef} from "react";

import {adminApiServiceCommon, adminSignalAlertApiService} from "services/AdminApiService";
import {useNavigate} from "react-router-dom";
import {UrlHelper} from "_utils/UrlHelper";
import {TradeModel} from "models/trade.model";
import {useProcessingModal} from "_hooks/useProcessingModal";
import {LinkAdminTradeSetup} from "components/admin/autotrade/LinkAdminTradeSetup";
import {ArrowClockwise, Clock} from "react-bootstrap-icons";
import {
  AnalysisHelper,
  DateHelper,
  ISignalAlert,
  ISignalAlert as Entity, ISignalAlertDetails, MathEx,
  NewYorkTz,
  SignalAlertCategoryEnum,
  SignalAlertStatusEnum,
  StockSignalsEnum,
  StockSignalsHelper
} from "predictagram-lib";
import {StatusDropDown} from "./StatusDropDown";
import {SignalAlertHelper} from "../../../../_utils/signal-alert.helper";
import {ReactTableHelper} from "../../../../_utils/ReactTableHelper";
import {getCoreRowModel, getSortedRowModel, SortingState, useReactTable} from "@tanstack/react-table";
import {Spinner} from "../../../common/Spinner";
import {DataGrid} from "../../common/DataGrid";
import {useApiServiceState} from "../../../../_hooks/useApiServiceState";
import {Field, Form, Formik} from "formik";
import {SignalAlertCategoriesDropDown} from "../../../common/SignalAlertCategoriesDropDown";
import {DropDownGeneric} from "../../../common/form/DropDownGeneric";
import {CsvHelper} from "../../../../_utils/CsvHelper";
import {ISignalAlertCache} from "predictagram-lib/src/interface/stock/analysis";

export const cacheStats = (row: ISignalAlertCache, simple?: boolean) => {
  const renderHist = (d: ISignalAlertDetails, pref: string) => {
    if (!d) {
      return 'N/A';
    }
    return `${pref}: ${(d.score.overall * 100).toFixed(2)}% trades ${d.trades.total}`;
  }
  return <div>
    <div>{renderHist(row.last7, 'Last 7')}</div>
    <div>{renderHist(row.last14, 'Last 14')}</div>
    <div>{renderHist(row.last30, 'Last 30')}</div>
    <div>{renderHist(row.last90, 'Last 90')}</div>
    {!simple && <>
      <div>{renderHist(row.last150, 'Last 150')}</div>
    </>}
  </div>
}


export const editSignalAlertLink = (row: {id:number}, label="Edit") => {
  return <button className="btn btn-secondary" type="button" onClick={() => {
    const url = `/admin/analysis/strategy-profit?id=${row.id}`;
    window.open(url, '_blank');
  }}>
    {label}
  </button>
}

interface Options {
  startTime?: number,
  endTime?: number,
  extraColumns: boolean,
  signalAlertOpenSignal?: StockSignalsEnum,
  signalAlertCloseSignal?: StockSignalsEnum,
  limit:number,
  signalAlertParentIds?: number[]
  signalAlertStatusIds?: SignalAlertStatusEnum[],
  signalAlertCategories?: SignalAlertCategoryEnum[],
  orderBy: any, // [{column: 'id',order: 'desc'}],
}


const FormFilter: React.FunctionComponent<{
  initialValues: Options,
  onClick: (search:Options)=>void,
}> = ({ initialValues, onClick }) => {

  const onSubmit = (dataIn: any, actions: any) => {
    const data = Object.assign({}, dataIn);
    onClick({
      ...data,
    });
  }

  const signalOptions = [
    {label:'---',value:null},
    ...Array.from(StockSignalsHelper.signalsMap().entries()).map(([s,n])=>{return {label:n,value:s}})
  ];
  const itemsLimit = [
    {label:'All',value:null},
    ...[100,200,500,1000].map((n)=>{return {label:n.toString(),value:n}})
  ];
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue }) => {
          return <Form>
            <div className="d-flex justify-content-start align-items-center gap-3">
              <div className="d-flex flex-column">
                <SignalAlertCategoriesDropDown name={'signalAlertCategories'}/>
              </div>
              <div>
                {/*<DropDownGeneric name="signalAlertStatusId" label={<span className='fw-bold'>Alert Status:</span>} options={signalOptions} />*/}
                <DropDownGeneric name="signalAlertOpenSignal" label={<span className='fw-bold'>Open Signal:</span>} options={signalOptions} />
                <DropDownGeneric name="signalAlertCloseSignal" label={<span className='fw-bold'>Close Signal:</span>}  options={signalOptions} />
              </div>
              <div>
                <DropDownGeneric name="limit" value={initialValues.limit} label={<span className='fw-bold'>Limit: </span>} options={itemsLimit} />

                <label>
                  <Field type="checkbox" name="extraColumns" />
                  <span className="mx-1 fw-bold">Extra Columns</span>
                </label>
              </div>
              <div className="align-self-end">
                <button type="submit" className="btn btn-primary "><ArrowClockwise /></button>
              </div>
            </div>

          </Form>
        }}

      </Formik>
    </div>
  )
}

export const AdminSignalAlertsList: React.FunctionComponent = () => {

  const todayDate = NewYorkTz.getDateMidnight(new Date()).getTime() / 1000;
  const endDate = 0; // todayDate + (24 * 60 * 60) - 1; // set to last minute of today

  const startDate = 0; // NewYorkTz.getDateMidnight(new Date()).getTime() / 1000-86400*30;

  // @TODO: set state doesn't work, not sure why
  // const [selectedAlerts,setSelectedAlerts] = useState<number[]>([]);
  const selectedAlerts = useRef<number[]>([]);
  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = e.target as HTMLInputElement;
    const val = parseInt(currentTarget.value);
    if (currentTarget.checked) {
      selectedAlerts.current.push(val);
      // setSelectedAlerts([val,...selectedAlerts]);
    } else {
      selectedAlerts.current = selectedAlerts.current.filter((i) => i !== val)
      // setSelectedAlerts(selectedAlerts.filter((i) => i !== val));
    }
    e.stopPropagation();
  }

  const initialSearch: Options = {
    // startTime: startDate,
    // endTime: endDate,
    limit: 100,
    orderBy: [{column:'id', order:'desc'}],
    extraColumns: false,
  }
  // const initialValues = {
  //   startTime: startDate,
  //   endTime: endDate,
  // } as Options;
  const searchOptions = useRef(initialSearch);

  const navigate = useNavigate();

  // const [showPerfCol, setShowPerfCol] = useState<boolean>(true);
  // const [showExtraCols, setShowExtraCols] = useState<boolean>(false);
  //
  // const [fieldsList, setFieldList] = useState<any>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  // const [gridColumns, setGridColumns] = useState<any[]>([]);
  const processingModal = useProcessingModal();
  const supplier = async(opts:Options) => {
    const data = (await adminApiServiceCommon.signalAlerts({filters:opts}));//{
      // signalAlertActivationStatusIds: refStatusId.current ? [refStatusId.current]:undefined as any,
    //});

    const columnHelper = ReactTableHelper.createHelper<Entity>();
    let columns = [
      columnHelper.columnBuilder('checked', {header:'', cell:(info)=>{
        const alertId = info.cell.row.original.id;
          return <input type="checkbox" /*checked={selectedAlerts.includes(alertId)}*/ value={alertId} onChange={(e)=>onSelect(e)} />

      }}).build(),
      columnHelper.columnBuilder('id', {header: 'ID'}).number().build(),
      columnHelper.columnBuilder('parentId', {header: 'Parent'}).number().build(),
      columnHelper.columnBuilder('name', {
        header: 'Name',
        cell:(info)=>getName(info.cell.row.original)
      }).build(),
      columnHelper.columnBuilder('notes', {header: 'Notes'}).build(),
      columnHelper.columnBuilder('emails', {header: 'Emails', cell:(info)=>info.cell.row.original.emails?.join(' ')}).build(),
      // columnHelper.columnBuilder('symbolName', {header: 'Symbol'}).build(),
      columnHelper.columnBuilder('categories', {header: 'Categories', cell:(info)=>info.cell.row.original.categories?.join(' ')}).build(),
      columnHelper.columnBuilder('createdAt', {header: 'Created', cell:(info)=>DateHelper.dateTimeFormatUs(info.cell.row.original.createdAt)}).build(),
      columnHelper.columnBuilder('todayScore', {header: 'Today Score', cell:(info)=>SignalAlertHelper.getAlertScoreToString(info.cell.row.original.todayScore||[])}).build(),
      columnHelper.columnBuilder('performance', {header: 'Performance', cell:(info)=><div style={{minWidth:'200px'}}>{cacheStats(info.cell.row.original.cache)}</div>}).build(),
      columnHelper.columnBuilder('performanceAfterCreated', {header: 'Performance After Created', cell:(info)=><div style={{minWidth:'200px'}}>{cacheStats(info.cell.row.original.cacheAfterCreated)}</div>}).build(),
      columnHelper.columnBuilder('statusId', {header: 'Status', cell:(info)=>signalStatus(info.cell.row.original)}).build(),
      columnHelper.columnBuilder('edit', {header: 'Edit', cell:(info)=>editSignalAlertLink(info.cell.row.original)}).build(),
    ];

    if (!opts.extraColumns) {
      columns = columns.filter(c=>!['Emails','Symbol','Categories','Performance After Created'].includes(c.header?.toString()||''));
    }

    return {data,columns};
  }

  const {items, state, reload, setSupplier} = useApiServiceState(()=>supplier(initialSearch));


  const onClickCallback = (opts: Options) => {
    searchOptions.current = opts;
    setSupplier(()=>supplier(opts));
  };

  const downloadReport = () => {
    const fileName = `alerts`;
    CsvHelper.downloadCsv(fileName, (items?.data||[]).map(a=>{
      const perf:any = {};
      for (const [pref,p] of [['PerfScore',a.cache],['PerfScoreAfterCreated',a.cacheAfterCreated]] as [string,ISignalAlertCache][]) {
        for (const k of Object.keys(p)) {
          // @ts-ignore
          const score = p[k] as ISignalAlertDetails ;
          perf[`${k}${pref}`] = MathEx.round(score.score.overall, 6);
        }
      }
      return Object.assign({
        id: a.id,
        parentId: a.parentId||'',
        name: a.name,
        categories: a.categories?.join(' '),
        direction: AnalysisHelper.directionString(a.data),
        createdEst: DateHelper.dateTimeFormatUs(a.createdAt),
        notes: a.notes,
        fingerprintShort: AnalysisHelper.analysisFingerPrint(a.data, ()=>a.symbolName, false),
        fingerprintFull: AnalysisHelper.analysisFingerPrint(a.data, ()=>a.symbolName, true),
      }, perf);
    }));

  }
  const getName = (v: Entity) =>
    <div className="d-flex gap-2">
      {v.name}
      <div role="button" className="text-primary" onClick={() => window.open(UrlHelper.getAdminCumeHistory(v.id), '_blank')} title="Cumulative Chart History"><Clock /></div>
    </div>


  const handleEditStatus = async (statusId: number, signalAlert: ISignalAlert) => {
    const payload = { statusId } as any;
    const oldValue = signalAlert.statusId;
    try {
      await adminSignalAlertApiService.update(payload, signalAlert.id);
      signalAlert.statusId = statusId;
    } catch (error: any) {
      signalAlert.statusId = oldValue;
      console.error(error);
      alert('Failed to update status. See error console.');
    }
  }

  const signalStatus = (signalAlert: ISignalAlert) => {
    return (
      <StatusDropDown setValue={(statusId: SignalAlertStatusEnum) => handleEditStatus(statusId, signalAlert)} value={signalAlert.statusId} />
    );
  }

  const createSetups = async () => {
    const created: [number, string, string][] = [];
    let errors:string[] = [];

    processingModal.setProcessing(true);
    processingModal.setShow(true);

    await Promise.all(selectedAlerts.current.map(async (id) => {
      try {
        const results = await TradeModel.createTradeSetupFromSignalAlert(id);
        results.map(result=>created.push([result.id, TradeModel.getSetupSecurityName(result) + ' ' + result.name, 'OK']));
      } catch (error: any) {
        console.error({ error });
        errors.push(`${id}: ${error.toString()}`);
      }
    }));

    processingModal.setContent(<div className="d-flex flex-column gap-2 p-3 text-12">
      <div key="created-resp">
        {created.length > 0 ?
          <><div className="fw-bold">Created:</div>
            <table><thead><tr><th>Trade Setup ID</th><th>Name</th></tr></thead>
              <tbody>
                {created.map(([id, name]) => <tr>
                  <td>{id}</td>
                  <td>{name}</td>
                  <td><LinkAdminTradeSetup tradeSetupId={id} /></td>
                </tr>)}
              </tbody></table>
          </>
          :
          <>None were created</>
        }
      </div>

      <div key="errors">
        {errors.length} errors occurred.
        {errors.length && errors.map((v,id)=><div key={id}>{v.toString()}</div>)}
      </div>

      <button type="button" className="btn btn-secondary text-12" onClick={() => processingModal.setShow(false)}>Close</button>
    </div>);
    processingModal.setProcessing(false);
  }

  const table = useReactTable<Entity>({
    data: items?.data || [],
    columns: items?.columns as any || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {sorting},
  });

  return (
    <>
      {processingModal.render()}
      {/*<div className="page-title position-absolute">Signal Alerts</div>*/}
      <div className="d-flex justify-content-between align-items-end text-12 ">

        {/*<Checkbox label="Show Profit" value={showPerfCol} onChange={() => setShowPerfCol(!showPerfCol)} />*/}
        <div><button className="btn btn-primary m-sm-1 p-sm-1" onClick={createSetups}>Create Trade Setups</button> </div>
        <FormFilter initialValues={initialSearch} onClick={onClickCallback} />

        <div><button className="btn btn-primary m-sm-1 p-sm-1"  onClick={downloadReport}>Download</button></div>
        <div><button className="btn btn-primary m-sm-1 p-sm-1" onClick={() => navigate(UrlHelper.getAdminSignalAlertAdd())}>Create New Signal Alert</button></div>
      </div>
      {state.isLoading() && <Spinner />}
      {state.isError() && <div>{state.error()?.message}</div>}
      {state.isLoaded() && items &&
       // DataGridTableColumns({data: items.data, columns: items.columns, sorting: true})
       <div className='fixed-table-head ' >
        <DataGrid table={table} className={'compact-table'}/>
       </div>
      }

    </>

  );
};
